<template>
  <v-container grid-list-xs v-if="!loading">
    <h3>My Calendar</h3>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="1">
        <v-menu transition="scale-transition" origin="right top" bottom left>
          <template v-slot:activator="{ on }">
            <v-btn class="ma-0" icon v-on="on">
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list class="app-card-action">
            <v-list-item to="/order/list">
              <v-icon class="mr-3">shopping_cart</v-icon>
              <span>{{ $t('Common.orders') }}</span>
            </v-list-item>
            <v-list-item @click="refresh">
              <v-icon class="mr-3 ">cached</v-icon>
              <span>{{ $t('Common.reload') }}</span>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" lg="3" class="pa-3 mb-3 feature-pane">
        <v-btn
          fab
          outlined
          small
          absolute
          left
          color="primary"
          @click="$refs.calendar.prev()"
        >
          <v-icon dark> keyboard_arrow_left </v-icon>
        </v-btn>

        <v-btn
          fab
          outlined
          small
          absolute
          right
          color="primary"
          @click="$refs.calendar.next()"
        >
          <v-icon dark> keyboard_arrow_right </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="orders">
      <v-col>
        <!-- now is normally calculated by itself, but to keep the calendar in this date range to view events -->
        <v-calendar
          ref="calendar"
          v-model="start"
          type="week"
          :start="start"
          :end="end"
          :now="now"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          first-interval="6"
          height="50"
        >
          <!-- the events at the bottom (timed) -->
          <template
            slot="dayBody"
            slot-scope="{ date, timeToY, minutesToPixels }"
          >
            <template v-for="event in eventsMap[date]">
              <!-- timed events -->
              <v-menu :key="event.id" v-model="event.open" full-width offset-x>
                <div
                  v-if="event.time"
                  slot="activator"
                  v-ripple
                  :key="event.id"
                  :style="{
                    top: timeToY(event.time) + 'px',
                    height: minutesToPixels(event.duration) + 'px'
                  }"
                  :class="icon(event.status).color + ' my-event with-time'"
                >
                  {{ foods(event) }}
                </div>
                <v-card color="grey lighten-4" min-width="450px" flat>
                  <v-toolbar color="primary" dark>
                    <v-btn icon> <v-icon>close</v-icon> </v-btn>
                    <v-toolbar-title> order# {{ event.id }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="8">
                        Buyer:
                        <router-link :to="'/profile/' + event.buyer.id">{{
                          event.buyer.name
                        }}</router-link>
                      </v-col>
                      <v-col cols="12" sm="4">
                        Amount:
                        <span class="indigo--text">{{
                          totalAmount(event)
                        }}</span>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="8">
                        Order Time:<span class="indigo--text">
                          {{ $moment(event.requestedDeliveryTime).fromNow() }}
                        </span>
                      </v-col>
                      <v-col cols="12" sm="4">
                        Delivery:
                        <span class="indigo--text">
                          {{ event.deliveryMethod }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="8">
                        Order detail:
                        <router-link :to="'/order/view/' + event.id"
                          >View</router-link
                        >
                      </v-col>
                      <v-col cols="12" sm="4">
                        Status:
                        <span class="indigo--text"
                          >{{ event.status }}
                        </span></v-col
                      >
                    </v-row>
                    <v-row :key="p.id" v-for="p in event.products">
                      <v-col cols="12" sm="8">
                        Food Name:
                        <span class="indigo--text">{{ p.dish.name }}</span>
                      </v-col>
                      <v-col cols="12" sm="2">
                        qty:
                        <span class="indigo--text">{{ p.orderAmount }}</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text color="secondary"> Cancel </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </template>
          </template>
        </v-calendar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
//import { OrderMixin } from '@/mixins/orderMixin'
import { OrderMixin } from 'fod-core/src/mixins/orderMixin'

const today = moment()
export default {
  mixins: [OrderMixin],
  data: () => ({
    today: today.format('YYYY-MM-DD'),
    start: today.format('YYYY-MM-DD'),
    end: today.format('YYYY-MM-DD'),
    now: null
  }),
  computed: {
    ...mapGetters({
      activeOrders: 'activeOrders',
      loading: 'loading',
      orders: 'orders'
    }),

    eventsMap() {
      const map = {}
      this.orders.forEach(order => {
        let date = moment(order.requestedDeliveryTime).format('YYYY-MM-DD')
        order.duration = 45
        //duration
        //order.time = '09:00 '
        order.time = moment(order.requestedDeliveryTime).format('HH:mm')
        return (map[date] = map[date] || []).push(order)
      })
      return map
    }
  },
  mounted() {
    this.getOrders()
  },
  methods: {
    getOrders() {
      this.$store.dispatch('loadOrders')
    },
    refresh() {
      this.getOrders()
    },
    foods(order) {
      let list = order.products.map(p => p.dish.name)
      return list.join(',')
    },
    totalAmount(order) {
      console.log()
      let total = 0
      console.log(order.products.map(p => (total += p.orderAmount)))
      return total
    }
  }
}
</script>
<style scoped>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  /* background-color: #1867c0; */
  color: #ffffff;
  border: 1px solid #1867c0;
  width: 100%;
  font-size: 12px;
  /* padding: 3px; */
  cursor: pointer;
  margin-bottom: 1px;
}

.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  /* background-color: #1867c0; */
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 2px;
  margin-right: 8px;
  position: relative;
}
.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
}
</style>
